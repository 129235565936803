import NotFound from '@/components/NotFound/NotFound';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import PageTopMarginWrapper from '@/components/ui/PageTopMarginWrapper/PageTopMarginWrapper';
import DefaultLayout from '@/layouts/default';

export default function Custom404Page() {
  return (
    <DefaultLayout showB2BModal={false}>
      <PageTopMarginWrapper>
        <PageMargins>
          <NotFound />
        </PageMargins>
      </PageTopMarginWrapper>
    </DefaultLayout>
  );
}
