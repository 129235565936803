import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

import PageMargins from '@/components/ui/PageMargins/PageMargins';
import { breadcrumbsPropType } from '@/constants/propTypes';
import { trackBreadcrumbClick } from '@/helpers/dataLayer';
import useFormatMessage from '@/hooks/useFormatMessage';

const PageBreadcrumbs = ({ breadcrumbs }) => {
  const formatMessage = useFormatMessage();

  const renderBreadcrumbLink = ({ pathname, title, key }) => (
    <MuiLink
      underline="hover"
      fontSize={12}
      component={Link}
      href={pathname}
      key={key}
      color="grayscale.600"
      onClick={trackBreadcrumbClick}>
      {title}
    </MuiLink>
  );

  const renderBreadcrumb = () => {
    const items = breadcrumbs.map((breadcrumb, i) => {
      const pathname = breadcrumb.metadata.pathname;
      const title =
        pathname === '/' ? formatMessage('breadcrumbs_home') : breadcrumb.title;
      const key = `${i}${pathname}`;

      if (i === breadcrumbs.length - 1) {
        return (
          <Typography key={key} fontSize={12} variant="a">
            {title}
          </Typography>
        );
      } else {
        return renderBreadcrumbLink({ pathname, title, key });
      }
    });

    // add home page breadcrumb if not existing in the breadcrumbs array
    if (breadcrumbs[0].metadata.pathname !== '/') {
      items.unshift(
        renderBreadcrumbLink({
          pathname: '/',
          title: formatMessage('breadcrumbs_home'),
          key: 'home',
        })
      );
    }

    return items;
  };

  if (!breadcrumbs || breadcrumbs.length === 1) {
    return null;
  }

  return (
    <PageMargins>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        sx={{ my: 2 }}
        aria-label="breadcrumb">
        {renderBreadcrumb()}
      </Breadcrumbs>
    </PageMargins>
  );
};

PageBreadcrumbs.propTypes = {
  breadcrumbs: breadcrumbsPropType,
};

export default PageBreadcrumbs;
